
import { getUrlWithFormat } from "@/utils/formatImgUrl";

export default {
  name: "CardHorizontal",
  components: {
    ButtonPlay: () => import("@/components/buttons/ButtonPlay"),
    Link: () => import("@/components/elements/Link.vue"),
    CardContent: () => import("@/components/cards/CardContent"),
  },
  props: ["card", "une", "mobileUne"],
  methods: {
    getUrlWithFormat,
  },
  computed: {
    isObfuscated() {
      if(this.card.type) {
        switch (this.card.type) {
          case 'show':
            return false
          case 'article':
            return false
          case 'episode':
            return true
          default:
            return true
        }
      }
      return true
    },
  },
};
